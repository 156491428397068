div {
  user-select: none;
  margin: 0;
  padding: 0;
  background: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: fantasy;
}

.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  color: #f7931a;
  background-image: url('../public/bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.App-box {
  margin-top: 1.5em;
  height: 33%;
  max-height: 50%;
  width: 50%;
  max-width: 90%;
  background: transparent;
  padding: 1em;
  font-size: 1.125em;
  border-radius: 1em;
  flex-direction: column;
}

p {
  margin-top: 3em;
  border-radius: 1.5em;
  padding: 1em;
  padding-top: 3em;
  padding-bottom: 3em;
  color: #f7931a;
  background: rgba(152, 0, 152, 0.229);
  box-shadow: 0 1px 5px, 0 1px 10px;
  text-shadow: 0 1px 5px, 0 1px 10px;
  animation: glowingAnimation 1s infinite;
}

span {
  color: #000;
  background: transparent;
}

a {
  color: #fff;
}

.inputWallet {
  margin: 2em;
  text-align: center;
  border-radius: .25em;
  max-width: 90%;
  width: 90%;
  overflow: hidden;
  padding: 1.5em;
  padding-bottom: 3em;
  color: #fff;
  font-size: 1.25em;
  background-color: #f7931a;
  cursor: default;
}

input:hover {
  color: #fff;
  background-color: #f7931a;
}

input {
  font-size: 1.25em;
  width: 80%;
  background: #000;
  margin-bottom: 3em;
  color: #f7931a;
  border-radius: .25em;
  cursor: pointer;
  padding: .5em;
}

.inputWallet:hover {
  background: #000;
  color: #f7931a;
}

@keyframes glowingAnimation {
  50% {
    transform: scale(1.1);
    box-shadow: 0px 1px 60px, 0px 1px 114px;
    text-shadow: 0px 1px 17px, 0px 1px 37px;
  }
}

@media (max-width: 600px) {

  .App-box {
    font-size: 1em;
  }

  @keyframes glowingAnimation {
    50% {
      transform: scale(1.1);
      box-shadow: 0px 1px 30px, 0px 1px 60px;
      text-shadow: 0px 1px 13px, 0px 1px 30px;
    }
  }

}

@media (max-width: 380px) {

  .App {
    overflow: auto;
  }

  .App-box {

    font-size: .75em;

  }

}